import React from 'react';
import ApplicationCard from './ApplicationCard';
import suaPousadaLogo from '../assets/suaPousadaLogo.png';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';

const ApplicationsList = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
    <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
      >
      <ApplicationCard 
        title="Sua Pousada " 
        description="Gerencie sua hotelaria através da Sua Pousada. Facilidade no controle de estadias e manutenção de quartos. Desenvolvido para atender a sua necessidade além de ser flexível para mudanças e deixar do seu jeito!" 
        logo={suaPousadaLogo}
        route="/suapousada"
      />
      {/* Adicione outros cards conforme necessário */}
    </motion.div>
    </Box>
  );
};

export default ApplicationsList;
